import { useRouter } from 'vue-router';
import { computed, ref, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { MobileAppOs } from 'web/src/modules/app-download/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getMobileAppOs } from 'web/src/modules/app-download/utils';
import { useAppDownloadStore } from 'web/src/modules/app-download/store';
export default function useAppDownload() {
    const appDownloadStore = useAppDownloadStore();
    const isAndroidAppEnabled = toRef(appDownloadStore, 'isAndroidAppEnabled');
    const isIosAppEnabled = toRef(appDownloadStore, 'isIosAppEnabled');
    const iosAppUrl = toRef(appDownloadStore, 'iosAppUrl');
    const androidAppUrl = toRef(appDownloadStore, 'androidAppUrl');
    const appOs = getMobileAppOs();
    const router = useRouter();
    const isLandingAndroidEnabled = toRef(useSiteConfigStore(), 'isLandingAndroidEnabled');
    function onAppDownload(inputAppOs) {
        switch(inputAppOs){
            case MobileAppOs.Android:
                if (isLandingAndroidEnabled.value) router.push({
                    name: RouteName.ANDROID
                });
                else window.location.href = androidAppUrl.value;
                break;
            case MobileAppOs.Ios:
                window.location.href = iosAppUrl.value;
                break;
            default:
                break;
        }
    }
    function appDownloadLink(inputAppOs) {
        switch(inputAppOs){
            case MobileAppOs.Android:
                return computed(()=>{
                    if (isLandingAndroidEnabled.value) return router.resolve({
                        name: RouteName.ANDROID
                    }).href;
                    return androidAppUrl.value;
                });
            case MobileAppOs.Ios:
                return iosAppUrl;
            default:
                return ref('/');
        }
    }
    function isAppDownloadEnabled(inputAppOs) {
        switch(inputAppOs){
            case MobileAppOs.Android:
                return isAndroidAppEnabled;
            case MobileAppOs.Ios:
                return isIosAppEnabled;
            default:
                return ref(false);
        }
    }
    return {
        appOs,
        onAppDownload,
        appDownloadLink,
        isAppDownloadEnabled
    };
}
