import { computed, ref, nextTick, toRef, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';
import RouteName from '@leon-hub/routing-config';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { useDocumentDimensionSafeSubscribe } from '@leon-hub/browser-composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useTheme } from 'web/src/modules/theme/composables';
import { useRootStore } from 'web/src/modules/core/store';
import { getNavigationItemIconLocation } from 'web/src/modules/core/utils';
import { useCustomHomepageNavigationItems } from 'web/src/modules/core/composables/navigation/useCustomHomepageNavigationItems';
import getNavigationItemByKey from './utils/getActiveItem';
import { binarySearchFirstUnfitElementIndex } from './utils';
const SportlinePages = [
    RouteName.HOME,
    RouteName.SPORTLINE_LIVE_EVENTS,
    RouteName.SPORTLINE_PREMATCH_EVENTS,
    RouteName.SPORTLINE_CYBERSPORT
];
export default function useHeaderNavigation(props, emit) {
    const container = ref();
    const baseList = ref();
    const router = useRouter();
    const analytics = useAnalytics();
    const route = useRoute();
    const authStore = useAuthStore();
    const { theme } = useTheme();
    const { imageCdnUrl } = useRootStore();
    const isLoggedIn = toRef(authStore, 'isLoggedIn');
    const initialContainerWidth = ref(0);
    const initialBaseListWidth = ref(0);
    const indexToSplitBaseList = ref(props.navigationItems?.length || 0);
    const activeId = ref();
    const { clientDimension } = useDocumentDimensionSafeSubscribe({
        debounce: 200
    });
    function convertToHeaderNavigationItem(navigationItem) {
        return {
            id: navigationItem.id,
            routeName: navigationItem.routeName,
            props: navigationItem.props,
            href: router.resolve(getNavigationItemRouteLocation(navigationItem, 'header')).href,
            label: navigationItem.caption || '',
            isActive: navigationItem.id === activeId.value,
            icon: navigationItem.icon,
            iconCdnSrc: getNavigationItemIconLocation(navigationItem.iconCdn?.src, theme.value, imageCdnUrl)
        };
    }
    const { filteredNavigationItems } = useCustomHomepageNavigationItems(toRef(props, 'navigationItems'));
    const headerNavigationItems = computed(()=>filteredNavigationItems.value.map((item)=>convertToHeaderNavigationItem(item)).filter((item)=>null !== item));
    const preparedNavigationList = computed(()=>{
        if (initialContainerWidth.value > 0 && clientDimension.value && (isLoggedIn.value || !isLoggedIn.value)) {
            const activeItemIndex = headerNavigationItems.value.findIndex((item)=>item.isActive);
            if (activeItemIndex >= indexToSplitBaseList.value && 0 !== indexToSplitBaseList.value) {
                const lastFitElementIndex = indexToSplitBaseList.value - 1;
                return [
                    ...headerNavigationItems.value.slice(0, lastFitElementIndex),
                    headerNavigationItems.value[activeItemIndex],
                    ...headerNavigationItems.value.slice(lastFitElementIndex, activeItemIndex),
                    ...headerNavigationItems.value.slice(activeItemIndex + 1)
                ];
            }
        }
        return headerNavigationItems.value;
    });
    const navigationList = computed(()=>clientDimension.value.clientWidth ? (preparedNavigationList.value || []).slice(0, indexToSplitBaseList.value) : []);
    const dropList = computed(()=>clientDimension.value.clientWidth ? (preparedNavigationList.value || []).slice(indexToSplitBaseList.value) : []);
    function getBaseListWidth() {
        return baseList?.value?.offsetWidth || 0;
    }
    function getContainerWidth() {
        return container?.value?.offsetWidth || 0;
    }
    const handleInitialSize = ()=>{
        if (container.value) {
            initialContainerWidth.value = getContainerWidth();
            initialBaseListWidth.value = getBaseListWidth();
        }
    };
    const realContainerWidth = ()=>{
        const dropContainerWidth = 54;
        return getContainerWidth() - dropContainerWidth;
    };
    watchEffect(()=>{
        if (initialContainerWidth.value > 0 && clientDimension.value.clientWidth) {
            indexToSplitBaseList.value = headerNavigationItems.value.length;
            nextTick().then(()=>{
                indexToSplitBaseList.value = binarySearchFirstUnfitElementIndex(baseList.value, realContainerWidth());
            });
        }
    });
    watchEffect(()=>{
        if (activeId.value) {
            const activeIdTopParent = getNavigationItemByKey('id', activeId.value, props.navigationItems);
            const hasChildrenNavigation = !!getNavigationItemByKey('routeName', route.name, activeIdTopParent?.children ?? []);
            if (hasChildrenNavigation) return;
        }
        activeId.value = getNavigationItemByKey('routeName', route.name, props.navigationItems)?.id;
    });
    useIntersectionObserver(container, handleInitialSize, void 0, true);
    function handleClick(item) {
        const { id, routeName, props: properties } = item;
        if (routeName && SportlinePages.includes(routeName)) emit('sportline-navigation', routeName);
        else {
            analytics.navigationItemClick({
                id,
                routeName,
                props: properties
            });
            router.push(getNavigationItemRouteLocation(item, 'header'));
        }
    }
    return {
        container,
        baseList,
        navigationList,
        dropList,
        handleClick,
        // for testing
        initialContainerWidth,
        initialBaseListWidth,
        activeId
    };
}
