import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = {
    class: "horizontal-navigation-button__label"
};
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderNavigationButton',
    props: {
        href: {
            default: ''
        },
        isActive: {
            type: Boolean
        },
        label: {
            default: ''
        },
        inverted: {
            type: Boolean
        },
        icon: {},
        iconCdnSrc: {},
        isPromotionsHighlighted: {
            type: Boolean
        },
        isLandingEuro: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const iconSize = IconSize.SIZE_16;
        function emitClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _ctx.href,
                class: _normalizeClass({
                    'horizontal-navigation-button': true,
                    'horizontal-navigation-button--active': _ctx.isActive,
                    'horizontal-navigation-button--inverted': _ctx.inverted,
                    'horizontal-navigation-button--promo-highlighted': _ctx.isPromotionsHighlighted,
                    'horizontal-navigation-button--landing': _ctx.isLandingEuro,
                    'horizontal-navigation-button--with-image': !!_ctx.iconCdnSrc
                }),
                onClick: _withModifiers(emitClick, [
                    "prevent"
                ])
            }, [
                _ctx.icon ? (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 0,
                    name: _ctx.icon.name,
                    size: _unref(iconSize),
                    class: _normalizeClass({
                        'horizontal-navigation-button__icon': true,
                        'horizontal-navigation-button__icon--landing': _ctx.isLandingEuro
                    })
                }, null, 8, [
                    "name",
                    "size",
                    "class"
                ])) : _ctx.iconCdnSrc ? (_openBlock(), _createBlock(VImage, {
                    key: 1,
                    src: _ctx.iconCdnSrc,
                    alt: "",
                    class: _normalizeClass({
                        'horizontal-navigation-button__icon': true,
                        'horizontal-navigation-button__icon--landing': _ctx.isLandingEuro
                    })
                }, null, 8, [
                    "src",
                    "class"
                ])) : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'HeaderNavigationButton'
                ]
            ]);
        };
    }
});
