import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = {
    key: 2
};
import { computed } from 'vue';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterNavigationListItem',
    props: {
        item: {},
        isLoggedIn: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { navigationItemClick } = useAnalytics();
        function onClick(navigationItem) {
            navigationItemClick(navigationItem, props.isLoggedIn);
        }
        const to = computed(()=>getNavigationItemRouteLocation(props.item, 'FooterNavigationItem'));
        return (_ctx, _cache)=>_ctx.item.url ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.item.url,
                class: _normalizeClass(_ctx.$style['link']),
                target: "_blank",
                rel: "noopener"
            }, [
                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                        _createTextVNode(_toDisplayString(_ctx.item.caption), 1)
                    ])
            ], 10, _hoisted_1)) : _ctx.item.routeName ? (_openBlock(), _createBlock(VRouterLink, {
                key: 1,
                to: to.value,
                "is-hidden-decoration": "",
                onClick: _cache[0] || (_cache[0] = ($event)=>onClick(_ctx.item))
            }, {
                default: _withCtx(()=>[
                        _createTextVNode(_toDisplayString(_ctx.item.caption), 1)
                    ]),
                _: 1
            }, 8, [
                "to"
            ])) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.item.caption), 1));
    }
});
