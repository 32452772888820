import { toRef, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { HomePageType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { IconSize } from '@leon-hub/icons';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useNavigationItemsStore, useRootStore, useScrollStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { LanguageSelectorPlace } from 'web/src/modules/i18n/components/LanguageSelector/enums';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
import { SocialType } from 'web/src/components/Social/VSocial/enums';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import { useThemeStore } from 'web/src/modules/theme/store';
import { getPartnershipLogos } from './utils';
import getTsupisLogos from './utils/getTsupisLogos';
export default function useFooterRouteComponent() {
    const route = useRoute();
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const { lang } = useI18nLocale();
    const bus = useEventsBus();
    const themeStore = useThemeStore();
    const { setLanguageSelectorPlace } = useI18n();
    const { isOddsFormatSwitcherEnabled } = useOddsSettings();
    const navigationStore = useNavigationItemsStore();
    const genericInfoNavigationItems = toRef(navigationStore, 'genericInfoNavigationItems');
    const bootstrapNavigationItems = toRef(navigationStore, 'bootstrapNavigationItems');
    const affiliateFooterNavigationItem = toRef(navigationStore, 'affiliateFooterNavigationItem');
    const telegramBotNavigationItem = toRef(navigationStore, 'telegramBotNavigationItem');
    const scrollStore = useScrollStore();
    const hasScrollableContent = toRef(scrollStore, 'hasScrollableContent');
    const scrollTop = toRef(scrollStore, 'scrollTop');
    const showScrollTopButton = computed(()=>hasScrollableContent.value && scrollTop.value > 0);
    const siteConfigStore = useSiteConfigStore();
    const bookmakerRatingsUrl = toRef(siteConfigStore, 'bookmakerRatingsUrl');
    const doShowSponsoredClubsLogos = toRef(siteConfigStore, 'isPartnersEnabled');
    const mobileAppCordovaBlock = toRef(siteConfigStore, 'mobileAppCordovaBlock');
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const socialMediaVkUrl = toRef(siteConfigStore, 'socialMediaVkUrl');
    const socialMediaInstagramUrl = toRef(siteConfigStore, 'socialMediaInstagramUrl');
    const socialMediaRssUrl = toRef(siteConfigStore, 'socialMediaRssUrl');
    const socialMediaTelegramUrl = toRef(siteConfigStore, 'socialMediaTelegramUrl');
    const isFooterSocialNetworksEnabled = toRef(siteConfigStore, 'isFooterSocialNetworksEnabled');
    const footerLogotypesBlock = toRef(siteConfigStore, 'footerLogotypesBlock');
    const doShowAppDownloadButtons = computed(()=>!!mobileAppCordovaBlock.value?.mobileAppDownloadEnabled);
    const rootStore = useRootStore();
    const currentLanguageFlag = toRef(rootStore, 'currentLanguageFlag');
    const isLanguageSwitcherAvailable = toRef(rootStore, 'isLanguageSwitcherAvailable');
    const isThemeFooterSwitcherEnabled = toRef(themeStore, 'isThemeFooterSwitcherEnabled');
    const isCustomHomePage = computed(()=>homePageType.value === HomePageType.CUSTOM && route.name === RouteName.HOME);
    const followUsList = computed(()=>{
        if (isFooterSocialNetworksEnabled.value) return footerLogotypesBlock.value?.socialNetworkLogotypesByLocale ?? [];
        return [];
    });
    const socialButtonsList = computed(()=>{
        const list = [];
        const commonProps = {
            size: IconSize.SIZE_24
        };
        if (socialMediaVkUrl.value) list.push({
            name: 'VKontakte',
            clickCollectName: 'VKontakte',
            socialType: SocialType.VK,
            url: socialMediaVkUrl.value,
            ...commonProps
        });
        if (socialMediaInstagramUrl.value) list.push({
            name: 'Instagram',
            clickCollectName: 'Instagram',
            socialType: SocialType.INSTAGRAM,
            url: socialMediaInstagramUrl.value,
            ...commonProps
        });
        if (socialMediaTelegramUrl.value) list.push({
            name: 'Telegram',
            clickCollectName: 'Telegram',
            socialType: SocialType.TELEGRAM,
            url: socialMediaTelegramUrl.value,
            ...commonProps
        });
        if (socialMediaRssUrl.value) list.push({
            name: 'Telegram',
            clickCollectName: 'Telegram',
            socialType: SocialType.RSS,
            url: socialMediaRssUrl.value.replace('{LANGUAGE_TAG}', lang.value),
            ...commonProps
        });
        return list;
    });
    const tsupisLogos = getTsupisLogos();
    const partnershipLogos = getPartnershipLogos();
    function goTop() {
        bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
    }
    function openLanguage() {
        "1";
        setLanguageSelectorPlace(LanguageSelectorPlace.FOOTER);
        router.push({
            name: RouteName.LANGUAGE
        });
    }
    return {
        isOddsFormatSwitcherEnabled,
        isCustomHomePage,
        showScrollTopButton,
        isLoggedIn,
        genericInfoNavigationItems,
        bootstrapNavigationItems,
        doShowAppDownloadButtons,
        affiliateFooterNavigationItem,
        socialButtonsList,
        telegramBotNavigationItem,
        tsupisLogos,
        partnershipLogos,
        bookmakerRatingsUrl,
        isLanguageSwitcherAvailable,
        isThemeFooterSwitcherEnabled,
        currentLanguageFlag,
        doShowSponsoredClubsLogos,
        followUsList,
        goTop,
        openLanguage
    };
}
