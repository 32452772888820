import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import BaseRouteName from '@leon-hub/routing-config-names/src/BaseRouteName';
import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import BalanceModal from 'web/src/modules/profile/components/BalanceModal/BalanceModal.vue';
import VBalance from 'web/src/components/Balance/VBalance/VBalance.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import HeaderAvatar from 'web/src/modules/core/components/HeaderAvatar/HeaderAvatar.vue';
import HeaderNotificationsButton from 'web/src/modules/core/components/HeaderNotificationsButton/HeaderNotificationsButton.vue';
import { getDefaultAvatarImage } from 'web/src/components/Avatar/utils';
import { useCustomerFinalBalance } from 'web/src/modules/customer/composables';
import { useHeaderProfile } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderProfile',
    setup (__props) {
        const { bonusValue, isLowBalance, isAddBalanceButtonAsCurrency, isHiddenBalance, isGamblePrevented, isBalanceModalShown, isBalanceOpened, closeBalanceModal, handleTopUp, showBalanceModal } = useHeaderProfile();
        const { totalBalance, isSumOfBalancesEnabled } = useCustomerFinalBalance(true);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("ul", {
                class: _normalizeClass(_ctx.$style['header-profile'])
            }, [
                (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    _createVNode(HeaderNotificationsButton)
                ])),
                _createVNode(VBalance, {
                    tag: _unref(Tag).LI,
                    class: _normalizeClass([
                        _ctx.$style['header-profile__item'],
                        _ctx.$style['header-profile__item--balance']
                    ]),
                    value: _unref(totalBalance),
                    "value-bonus": _unref(bonusValue),
                    "is-low-balance": _unref(isLowBalance),
                    "is-add-button-as-currency": _unref(isAddBalanceButtonAsCurrency),
                    "is-balance-hidden": _unref(isHiddenBalance),
                    "is-sum-of-balances-enabled": _unref(isSumOfBalancesEnabled),
                    "is-header": "",
                    onTopUp: _unref(handleTopUp),
                    onClickButtonGift: _unref(showBalanceModal)
                }, null, 8, [
                    "tag",
                    "class",
                    "value",
                    "value-bonus",
                    "is-low-balance",
                    "is-add-button-as-currency",
                    "is-balance-hidden",
                    "is-sum-of-balances-enabled",
                    "onTopUp",
                    "onClickButtonGift"
                ]),
                _createCommentVNode("", true),
                _createCommentVNode("", true),
                (_openBlock(), _createElementBlock("li", {
                    key: 3,
                    class: _normalizeClass(_ctx.$style['header-profile__item'])
                }, [
                    _createVNode(_component_router_link, {
                        to: {
                            name: _unref(BaseRouteName).PROFILE
                        }
                    }, {
                        default: _withCtx(()=>[
                                _createVNode(VButton, {
                                    "icon-name": _unref(IconName).PERSON_FILL,
                                    "icon-size": _unref(IconSize).SIZE_24,
                                    height: _unref(ButtonHeight).SMALL,
                                    kind: _unref(ButtonKind).HEADER_DARK_VERSION,
                                    rounded: "",
                                    class: _normalizeClass({
                                        [_ctx.$style['header-profile__avatar']]: true,
                                        [_ctx.$style['header-profile__avatar--identification']]: _unref(isGamblePrevented)
                                    })
                                }, null, 8, [
                                    "icon-name",
                                    "icon-size",
                                    "height",
                                    "kind",
                                    "class"
                                ]),
                                _unref(isGamblePrevented) ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass([
                                        _ctx.$style['header-profile__item-badge'],
                                        _ctx.$style['header-profile__item-badge--danger']
                                    ])
                                }, null, 2)) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    }, 8, [
                        "to"
                    ])
                ], 2)),
                _unref(isBalanceModalShown) ? (_openBlock(), _createBlock(BalanceModal, {
                    key: 4,
                    "is-open": _unref(isBalanceOpened),
                    onClose: _unref(closeBalanceModal)
                }, null, 8, [
                    "is-open",
                    "onClose"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeaderProfile'
                ]
            ]);
        };
    }
});
