import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBonusStore } from 'web/src/modules/bonuses/store';
export default function useBalanceModal(emit) {
    const bonusStore = useBonusStore();
    const router = useRouter();
    const { $translate } = useI18n();
    const bonusCbc = toRef(bonusStore, 'bonusCbc');
    const balanceMode = toRef(bonusStore, 'balanceMode');
    function emitClose() {
        emit('close');
    }
    function onClick() {
        "1";
        if (bonusCbc.value) {
            const { actionUrl, category } = bonusCbc.value;
            if (!actionUrl) {
                router.push({
                    name: CasinoRouteName.CASINO_LOBBY
                });
                return;
            }
            router.push({
                name: RouteName.PROMOTION_DETAILS,
                params: {
                    categoryId: category.toLowerCase(),
                    actionUrl
                }
            });
        } else router.push({
            name: CasinoRouteName.CASINO_LOBBY
        });
        emitClose();
    }
    const listItemAccordionTitle = computed(()=>$translate('WEB2_WHAT_IS_CBC_BALANCE').value);
    return {
        listItemAccordionTitle,
        balanceMode,
        onClick,
        emitClose
    };
}
