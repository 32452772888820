import { computed, toRef, nextTick, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { noop } from 'lodash';
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config';
import { CasinoRouteName, isEGSRoute } from '@leon-hub/routing-config-names';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useNavigationItemsStore, useRootNavigationStore, useRootStore, useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useThemeStore } from 'web/src/modules/theme/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { navigateToLocationsWithCallbacks, resolveHeaderBarsSportlinePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { LanguageSelectorPlace } from 'web/src/modules/i18n/components/LanguageSelector/enums';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useHeaderBarRouteComponent() {
    const { $translate, setLanguageSelectorPlace } = useI18n();
    const { isLoggedIn } = useIsLoggedIn();
    const bus = useEventsBus();
    const router = useRouter();
    const route = useRoute();
    const siteConfigStore = useSiteConfigStore();
    const customerFinanceStore = useCustomerFinanceStore();
    const rootStore = useRootStore();
    const navigationItemsStore = useNavigationItemsStore();
    const formattedBalance = toRef(customerFinanceStore, 'formattedBalance');
    const isThemeSwitcherAvailable = toRef(siteConfigStore, 'isThemeSwitcherEnabled');
    const isPromotionsHighlightLinkEnabled = toRef(siteConfigStore, 'isPromotionsHighlightLinkEnabled');
    const isLandingFestivalEnabled = toRef(siteConfigStore, 'isLandingFestivalEnabled');
    const currentLanguageFlag = toRef(rootStore, 'currentLanguageFlag');
    const isLanguageSwitcherAvailable = toRef(rootStore, 'isLanguageSwitcherAvailable');
    const isSidebarToggleVisible = computed(()=>!route.meta.isLeftSidebarToggleHidden);
    const topNavigationItems = toRef(navigationItemsStore, 'topNavigationItems');
    const isEGSHomePageType = toRef(useRouterStore(), 'isEGSHomePageType');
    const hasCustomTheme = toRef(useThemeStore(), 'hasCustomTheme');
    const menuButtonProperties = {
        kind: ButtonKind.TRANSPARENT_DARK_VERSION,
        iconSize: IconSize.SIZE_24,
        iconName: IconName.HAMBURGER,
        height: ButtonHeight.LARGE
    };
    const searchButtonProps = {
        iconName: IconName.SEARCH,
        iconSize: IconSize.SIZE_20,
        height: ButtonHeight.SMALL,
        kind: ButtonKind.HEADER_DARK_VERSION,
        rounded: true
    };
    const brandName = $translate('WEB2_BRAND_NAME_SHORT');
    const menuOverflowHidden = ref(true);
    const isSimplifiedLogo = computed(()=>Boolean("1") && isLoggedIn.value);
    function toggleTheme() {
        useThemeStore().toggleTheme();
    }
    function toggleSidebar() {
        bus.emit(BusEvent.SIDE_MENU_TOGGLE, {});
    }
    function handleSportlineNavigation(to) {
        const { persistLocation } = useSportlinePersistLocation();
        const location = resolveHeaderBarsSportlinePageLink(to, persistLocation.value);
        navigateToLocationsWithCallbacks(router, location, {
            beforeNavigation: async ()=>{
                const navigationStore = useRootNavigationStore();
                if (void 0 !== navigationStore.isRouteContentLoading[to]) return;
                navigationStore.setOnlyFirstRouteContentLoading(to);
                await nextAnimationFrame();
                await nextTick();
                await nextAnimationFrame();
            }
        });
    }
    function searchClick() {
        let searchRoute;
        "1";
        searchRoute = isEGSRoute(route.name, isEGSHomePageType.value) ? CasinoRouteName.CASINO_SEARCH : RouteName.SEARCH;
        router.push({
            name: searchRoute
        });
    }
    function onMouseOverSearch() {
        import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue').catch(noop);
        "1";
        import('web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue').catch(noop);
    }
    function openLanguage() {
        "1";
        setLanguageSelectorPlace(LanguageSelectorPlace.HEADER);
        router.push({
            name: RouteName.LANGUAGE
        });
    }
    function setMenuOverflowVisible(isVisible) {
        menuOverflowHidden.value = !isVisible;
    }
    return {
        menuOverflowHidden,
        menuButtonProperties,
        isSidebarToggleVisible,
        toggleSidebar,
        brandName,
        isLoggedIn,
        topNavigationItems,
        formattedBalance,
        isPromotionsHighlightLinkEnabled,
        isLandingFestivalEnabled,
        handleSportlineNavigation,
        setMenuOverflowVisible,
        searchButtonProps,
        onMouseOverSearch,
        searchClick,
        isLanguageSwitcherAvailable,
        currentLanguageFlag,
        openLanguage,
        isThemeSwitcherAvailable,
        toggleTheme,
        isSimplifiedLogo,
        hasCustomTheme
    };
}
