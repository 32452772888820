import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "language-switcher-button__visual"
};
const _hoisted_2 = {
    class: "language-switcher-button__language"
};
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { useIsGlobeIcon } from 'web/src/modules/i18n/components/LanguageSelector/composables';
import VCountryFlag from 'web/src/components/CountryFlag/VCountryFlag/VCountryFlag.vue';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LanguageSwitcherButton',
    props: {
        name: {
            default: 'RU'
        },
        touchSize: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        isHeader: {
            type: Boolean
        }
    },
    emits: [
        "open-language"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const pointingProperties = {
            name: IconName.TRIANGLE_DOWN,
            size: IconSize.SIZE_16
        };
        const iconProperties = {
            name: IconName.GLOBE,
            size: IconSize.SIZE_20
        };
        const { isGlobeIcon } = useIsGlobeIcon();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass({
                    ['language-switcher-button']: true,
                    ['language-switcher-button--touch-size']: _ctx.touchSize,
                    ['language-switcher-button--no-background']: _ctx.noBackground,
                    ['language-switcher-button--is-header']: _ctx.isHeader
                }),
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('open-language'))
            }, [
                _createElementVNode("span", _hoisted_1, [
                    _ctx.name && _unref(isGlobeIcon)(_ctx.name.toLowerCase()) ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                        key: 0
                    }, iconProperties, {
                        class: "language-switcher-button__icon"
                    }), null, 16)) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        (_openBlock(), _createBlock(VCountryFlag, {
                            key: 1,
                            class: "language-switcher-button__icon",
                            name: _ctx.name,
                            size: _unref(VCountryFlagSize).SIZE_16,
                            rounded: ""
                        }, null, 8, [
                            "name",
                            "size"
                        ]))
                    ], 64)),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
                    _createVNode(_unref(VIcon), _mergeProps(pointingProperties, {
                        class: "language-switcher-button__arrow"
                    }), null, 16)
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LanguageSwitcherButton'
                ]
            ]);
        };
    }
});
