import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useTheme } from 'web/src/modules/theme/composables';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ThemeSwitcher',
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isDark } = useTheme();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['theme-switcher'])
            }, [
                _createVNode(VSwitch, {
                    kind: _unref(SwitchKind).THEME,
                    checked: _unref(isDark),
                    onChange: _cache[0] || (_cache[0] = ($event)=>emit('change'))
                }, null, 8, [
                    "kind",
                    "checked"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'ThemeSwitcher'
                ]
            ]);
        };
    }
});
