import { computed, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { useCustomerDataStore, useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { usePaymentsDepositsNavigation } from 'web/src/modules/core/composables/navigation';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { isCasinoGameRouteName } from 'web/src/modules/casino/utils';
const storageKey = 'isOpenBalance';
export default function useHeaderProfile() {
    const bonusStore = useBonusStore();
    const route = useRoute();
    const customerDataStore = useCustomerDataStore();
    const localStorageManager = useLocalStorageManager();
    const financeStore = useCustomerFinanceStore();
    const { pushToDepositsPage } = usePaymentsDepositsNavigation();
    const analyticsService = useAnalytics();
    const bonusCbc = toRef(bonusStore, 'bonusCbc');
    const bonusValue = computed(()=>bonusCbc.value?.walletBalanceNumber || 0);
    const isLowBalance = toRef(financeStore, 'isLowBalance');
    const isAddBalanceButtonAsCurrency = toRef(financeStore, 'isAddBalanceButtonAsCurrency');
    const isHiddenBalance = computed(()=>{
        if (isCasinoGameRouteName(route.name)) return true;
        return customerDataStore.isHiddenBalance;
    });
    const isGamblePrevented = ref(false);
    const isBalanceModalShown = ref(false);
    const isBalanceOpened = ref(true);
    function handleTopUp() {
        analyticsService.clickMap({
            deposit: 'headerBalance'
        });
        pushToDepositsPage();
    }
    function showBalanceModal() {
        analyticsService.clickMap({
            bonuses: 'bonusBalanceIcon'
        });
        const storageValue = localStorageManager.getItem(storageKey);
        if (storageValue) isBalanceOpened.value = false;
        else localStorageManager.setItem(storageKey, '1');
        isBalanceModalShown.value = true;
    }
    function closeBalanceModal() {
        isBalanceModalShown.value = false;
    }
    return {
        bonusValue,
        isLowBalance,
        isAddBalanceButtonAsCurrency,
        isHiddenBalance,
        isGamblePrevented,
        isBalanceModalShown,
        isBalanceOpened,
        handleTopUp,
        showBalanceModal,
        closeBalanceModal
    };
}
