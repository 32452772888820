import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import useAppDownload from 'web/src/modules/app-download/composables/useAppDownload';
import { MobileAppOs } from 'web/src/modules/app-download/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppDownloadButtons',
    props: {
        text: {}
    },
    setup (__props) {
        const analytics = useAnalytics();
        const { isAppDownloadEnabled, onAppDownload } = useAppDownload();
        function onDownloadAndroid() {
            onAppDownload(MobileAppOs.Android);
            analytics.clickMap({
                downloadMobileAppIcons: MobileAppOs.Android
            });
        }
        function onDownloadIos() {
            onAppDownload(MobileAppOs.Ios);
            analytics.clickMap({
                downloadMobileAppIcons: MobileAppOs.Ios
            });
        }
        const isIosDownloadEnabled = computed(()=>isAppDownloadEnabled(MobileAppOs.Ios).value);
        const isAndroidDownloadEnabled = computed(()=>isAppDownloadEnabled(MobileAppOs.Android).value);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "app-buttons",
                    {
                        [_ctx.$style['app-buttons']]: true,
                        [_ctx.$style['app-buttons--all']]: isIosDownloadEnabled.value && isAndroidDownloadEnabled.value
                    }
                ])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['app-buttons__buttons'])
                }, [
                    isIosDownloadEnabled.value ? (_openBlock(), _createBlock(VButton, {
                        key: 0,
                        kind: _unref(ButtonKind).BASE,
                        height: _unref(ButtonHeight).LARGE,
                        "icon-size": _unref(IconSize).SIZE_24,
                        "icon-name": _unref(IconName).OS_IOS,
                        class: _normalizeClass(_ctx.$style['app-buttons__item']),
                        onClick: onDownloadIos
                    }, null, 8, [
                        "kind",
                        "height",
                        "icon-size",
                        "icon-name",
                        "class"
                    ])) : _createCommentVNode("", true),
                    isAndroidDownloadEnabled.value ? (_openBlock(), _createBlock(VButton, {
                        key: 1,
                        kind: _unref(ButtonKind).BASE,
                        height: _unref(ButtonHeight).LARGE,
                        "icon-size": _unref(IconSize).SIZE_24,
                        "icon-name": _unref(IconName).OS_ANDROID,
                        class: _normalizeClass(_ctx.$style['app-buttons__item']),
                        onClick: onDownloadAndroid
                    }, null, 8, [
                        "kind",
                        "height",
                        "icon-size",
                        "icon-name",
                        "class"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _ctx.text ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['app-buttons__text'])
                }, _toDisplayString(_ctx.text), 3)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AppDownloadButtons'
                ]
            ]);
        };
    }
});
