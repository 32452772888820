import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { Deferred } from '@leon-hub/utils';
let AbstractPrefetch = class AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    computeCacheKey() {
        return Symbol(Date.now() + Math.random());
    }
    resetCache() {
        this.lastCacheKey = Symbol(Date.now() + Math.random());
    }
    /**
   * @return cache is changed?
   */ updateCache() {
        const value = this.computeCacheKey();
        if (void 0 !== value) {
            const { lastCacheKey } = this;
            const isChanged = lastCacheKey !== value;
            this.lastCacheKey = value;
            return isChanged;
        }
        return false;
    }
    async start() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        if (this.deferred.finished && this.updateCache()) {
            this.deferred = new Deferred();
            try {
                await this.prefetch(...args);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('Error during prefetch %s:', args[1].name, err);
            } finally{
                this.deferred.resolve();
            }
        } else args[3]();
        return this.deferred.promise;
    }
    constructor(){
        _define_property(this, "deferred", void 0);
        _define_property(this, "lastCacheKey", Symbol('cache-key'));
        this.deferred = new Deferred();
        this.deferred.resolve();
    }
};
export { AbstractPrefetch as default };
