import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { VScrollbar } from '@components/v-scrollbar';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import Balance from 'web/src/modules/profile/components/BalanceUser/BalanceUser.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import RequestedAmount from 'web/src/modules/profile/components/RequestedAmount/RequestedAmount.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';
import { useBalanceModal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BalanceModal',
    props: {
        isOpen: {
            type: Boolean
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { listItemAccordionTitle, balanceMode, onClick, emitClose } = useBalanceModal(emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY,
                transition: _unref(ModalTransition).SLIDE_UP
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['balance-modal'])
                        }, [
                            _createVNode(VOverlay),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['balance-modal__wrapper'])
                            }, [
                                _createVNode(ModalWindowHeader, {
                                    class: _normalizeClass(_ctx.$style['balance-modal__header']),
                                    title: _ctx.$t('WEB2_MY_BALANCE'),
                                    "suffix-icon-name": _unref(IconName).CROSS,
                                    type: 'default',
                                    "is-close-button-outside": "",
                                    onSuffixClick: _unref(emitClose)
                                }, null, 8, [
                                    "class",
                                    "title",
                                    "suffix-icon-name",
                                    "type",
                                    "onSuffixClick"
                                ]),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['balance-modal__scrollbar'])
                                }, [
                                    _createVNode(_unref(VScrollbar), null, {
                                        default: _withCtx(()=>[
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['balance-modal__inner'])
                                                }, [
                                                    _createVNode(Balance, {
                                                        class: _normalizeClass(_ctx.$style['balance-modal__balances']),
                                                        mode: _unref(balanceMode),
                                                        "is-in-profile": false,
                                                        onClose: _unref(emitClose)
                                                    }, null, 8, [
                                                        "class",
                                                        "mode",
                                                        "onClose"
                                                    ]),
                                                    _createVNode(RequestedAmount, {
                                                        class: _normalizeClass(_ctx.$style['balance-modal__withdrawal-request']),
                                                        onClick: _unref(emitClose),
                                                        onClose: _unref(emitClose)
                                                    }, null, 8, [
                                                        "class",
                                                        "onClick",
                                                        "onClose"
                                                    ]),
                                                    _createVNode(VList, {
                                                        "independent-items": ""
                                                    }, {
                                                        default: _withCtx(()=>[
                                                                (_openBlock(), _createBlock(VListItemAccordion, {
                                                                    key: 0,
                                                                    id: "1",
                                                                    open: _ctx.isOpen,
                                                                    title: _unref(listItemAccordionTitle)
                                                                }, {
                                                                    content: _withCtx(()=>[
                                                                            _createVNode(VCmsContent, {
                                                                                "cms-key": "WEB2_CBC_BALANCE_DES",
                                                                                class: _normalizeClass(_ctx.$style['cms-content']),
                                                                                silent: "",
                                                                                loader: "",
                                                                                onClickLink: _unref(onClick)
                                                                            }, null, 8, [
                                                                                "class",
                                                                                "onClickLink"
                                                                            ])
                                                                        ]),
                                                                    _: 1
                                                                }, 8, [
                                                                    "open",
                                                                    "title"
                                                                ])),
                                                                _createVNode(VListItemAccordion, {
                                                                    id: "2",
                                                                    title: _ctx.$t('WEB2_WHAT_IS_MAIN_BALANCE')
                                                                }, {
                                                                    content: _withCtx(()=>[
                                                                            _createElementVNode("div", {
                                                                                class: _normalizeClass(_ctx.$style['content'])
                                                                            }, _toDisplayString(_ctx.$t('WEB2_MAIN_BALANCE_DES')), 3)
                                                                        ]),
                                                                    _: 1
                                                                }, 8, [
                                                                    "title"
                                                                ])
                                                            ]),
                                                        _: 1
                                                    })
                                                ], 2)
                                            ]),
                                        _: 1
                                    })
                                ], 2)
                            ], 2)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "selector",
                "transition"
            ])), [
                [
                    _directive_auto_id,
                    'BalanceModal'
                ]
            ]);
        };
    }
});
