import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, mergeProps as _mergeProps, createSlots as _createSlots } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import FooterSponsoredClubsLogos from 'web/src/modules/core/views/FooterSponsoredClubsLogos/FooterSponsoredClubsLogos.vue';
import FooterCopyright from 'web/src/modules/core/components/FooterCopyright/FooterCopyright.vue';
import FooterLogos from 'web/src/modules/core/components/FooterLogos/FooterLogos.vue';
import FooterSeo from 'web/src/modules/core/views/FooterSeo/FooterSeo.vue';
import FooterPartnershipLogos from 'web/src/modules/core/components/FooterPartnershipLogos/FooterPartnershipLogos.vue';
import FooterNavigationListItem from 'web/src/modules/core/components/FooterNavigationListItem/FooterNavigationListItem.vue';
import FooterNavigationList from 'web/src/modules/core/components/FooterNavigationList/FooterNavigationList.vue';
import FooterFollowUs from 'web/src/modules/core/views/FooterFollowUs/FooterFollowUs.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import OddsSwitcherButton from 'web/src/modules/sportline/submodules/odds/views/OddsSwitcherButton.vue';
import ThemeSwitcherButton from 'web/src/modules/theme/views/ThemeSwitcherButton.vue';
import { useFooterRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterRouteComponent',
    props: {
        isFullWidth: {
            type: Boolean
        },
        isSimpleLayout: {
            type: Boolean
        }
    },
    setup (__props) {
        const FooterLicense = require('web/src/modules/core/views/FooterLicense/FooterLicense.vue').default;
        // eslint-disable-next-line max-len
        const LanguageSwitcherButton = // eslint-disable-next-line max-len
        require('web/src/modules/i18n/components/LanguageSelector/components/LanguageSwitcherButton/LanguageSwitcherButton.vue').default;
        const AppDownloadButtons = // eslint-disable-next-line unicorn/prefer-module
        require('web/src/modules/app-download/views/AppDownloadButtons/AppDownloadButtons.vue').default;
        const VSocial = // eslint-disable-next-line unicorn/prefer-module
        require('web/src/components/Social/VSocial/VSocial.vue').default;
        // eslint-disable-next-line unicorn/prefer-module
        const { isOddsFormatSwitcherEnabled, isLoggedIn, genericInfoNavigationItems, bootstrapNavigationItems, doShowAppDownloadButtons, affiliateFooterNavigationItem, socialButtonsList, telegramBotNavigationItem, tsupisLogos, partnershipLogos, bookmakerRatingsUrl, isLanguageSwitcherAvailable, isThemeFooterSwitcherEnabled, currentLanguageFlag, doShowSponsoredClubsLogos, followUsList, showScrollTopButton, goTop, openLanguage } = useFooterRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("footer", {
                class: _normalizeClass({
                    [_ctx.$style['footer']]: true,
                    [_ctx.$style['footer--visible']]: true,
                    [_ctx.$style['footer--full-width']]: _ctx.isFullWidth,
                    [_ctx.$style['footer--simplified']]: void 0
                })
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(isLoggedIn) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(FooterSeo, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['footer__special'])
                    }, null, 8, [
                        "class"
                    ])),
                    _createCommentVNode("", true),
                    (_openBlock(), _createElementBlock("section", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['footer__menu'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['footer__column'])
                        }, [
                            (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['footer__menu-item--title'])
                            }, _toDisplayString(_ctx.$t('WEB2_INFORMATION')), 3)),
                            _createVNode(FooterNavigationList, {
                                "navigation-items": _unref(genericInfoNavigationItems),
                                "is-logged-in": _unref(isLoggedIn),
                                class: _normalizeClass(_ctx.$style['footer__menu-list'])
                            }, null, 8, [
                                "navigation-items",
                                "is-logged-in",
                                "class"
                            ])
                        ], 2),
                        (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['footer__column'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['footer__menu-item--title'])
                            }, _toDisplayString(_ctx.$t('WEB2_WHERE_TO_BEGIN')), 3),
                            _createVNode(FooterNavigationList, {
                                "navigation-items": _unref(bootstrapNavigationItems),
                                "is-logged-in": _unref(isLoggedIn)
                            }, null, 8, [
                                "navigation-items",
                                "is-logged-in"
                            ])
                        ], 2)),
                        (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['footer__column'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['footer__menu-item--title'])
                            }, _toDisplayString(_ctx.$t('WEB2_BRAND_NAME_FULL')), 3),
                            _unref(doShowAppDownloadButtons) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['footer__app'])
                            }, [
                                _createVNode(_unref(AppDownloadButtons), {
                                    text: _ctx.$t('WEB2_GUEST_SLIP_APP')
                                }, null, 8, [
                                    "text"
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _unref(affiliateFooterNavigationItem) ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['footer__aff'])
                            }, [
                                _createVNode(FooterNavigationListItem, {
                                    item: _unref(affiliateFooterNavigationItem),
                                    "is-logged-in": _unref(isLoggedIn),
                                    class: _normalizeClass(_ctx.$style['footer__aff-title'])
                                }, {
                                    default: _withCtx(()=>[
                                            _withDirectives((_openBlock(), _createElementBlock("span", null, [
                                                _createTextVNode(_toDisplayString(_unref(affiliateFooterNavigationItem).caption), 1)
                                            ])), [
                                                [
                                                    _directive_collect,
                                                    {
                                                        affiliateProgramLink: 'true'
                                                    },
                                                    "click-counter"
                                                ]
                                            ])
                                        ]),
                                    _: 1
                                }, 8, [
                                    "item",
                                    "is-logged-in",
                                    "class"
                                ]),
                                _unref(affiliateFooterNavigationItem).label ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['footer__aff-text'])
                                }, _toDisplayString(_unref(affiliateFooterNavigationItem).label), 3)) : _createCommentVNode("", true)
                            ], 2)) : _createCommentVNode("", true),
                            _unref(socialButtonsList).length ? (_openBlock(), _createElementBlock("ul", {
                                key: 2,
                                class: _normalizeClass(_ctx.$style['footer__social-list'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(socialButtonsList), (param)=>{
                                    let { name, clickCollectName, ...props } = param;
                                    return _openBlock(), _createElementBlock("li", {
                                        key: clickCollectName
                                    }, [
                                        _withDirectives(_createVNode(_unref(VSocial), _mergeProps({
                                            class: _ctx.$style['footer__social-button'],
                                            ref_for: true
                                        }, props, {
                                            title: name
                                        }), null, 16, [
                                            "class",
                                            "title"
                                        ]), [
                                            [
                                                _directive_collect,
                                                {
                                                    socialMediaIcons: clickCollectName
                                                },
                                                "click-counter"
                                            ]
                                        ])
                                    ]);
                                }), 128))
                            ], 2)) : _createCommentVNode("", true),
                            _unref(telegramBotNavigationItem) ? (_openBlock(), _createElementBlock("ul", {
                                key: 3,
                                class: _normalizeClass(_ctx.$style['footer__social-list'])
                            }, [
                                _createElementVNode("li", null, [
                                    _createVNode(VRouterLink, {
                                        class: _normalizeClass(_ctx.$style['footer__social-link']),
                                        to: {
                                            name: _unref(telegramBotNavigationItem).routeName || _unref(RouteName).HOME
                                        }
                                    }, {
                                        default: _withCtx(()=>[
                                                _createVNode(_unref(VIcon), {
                                                    size: _unref(IconSize).SIZE_24,
                                                    name: _unref(IconName).TELEGRAM_CIRCLE,
                                                    class: _normalizeClass(_ctx.$style['footer__social-icon'])
                                                }, null, 8, [
                                                    "size",
                                                    "name",
                                                    "class"
                                                ]),
                                                _unref(telegramBotNavigationItem).props && _unref(telegramBotNavigationItem).props.telegramBotId ? (_openBlock(), _createElementBlock("span", {
                                                    key: 0,
                                                    class: _normalizeClass(_ctx.$style['footer__social-text'])
                                                }, _toDisplayString(_unref(telegramBotNavigationItem).props.telegramBotId), 3)) : _createCommentVNode("", true)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "class",
                                        "to"
                                    ])
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _unref(followUsList).length ? (_openBlock(), _createBlock(FooterFollowUs, {
                                key: 4
                            })) : _createCommentVNode("", true)
                        ], 2))
                    ], 2)),
                    (_unref(followUsList).length, _createCommentVNode("", true)),
                    _createCommentVNode("", true),
                    (_openBlock(), _createBlock(FooterLogos, {
                        key: 5
                    }, _createSlots({
                        _: 2
                    }, [
                        void 0,
                        void 0,
                        void 0
                    ]), 1024)),
                    _createElementVNode("section", {
                        class: _normalizeClass(_ctx.$style['footer__bottom-container'])
                    }, [
                        _createElementVNode("section", {
                            class: _normalizeClass({
                                [_ctx.$style['footer__bottom']]: true,
                                [_ctx.$style['footer__bottom--with-lang-switch']]: _unref(isLanguageSwitcherAvailable)
                            })
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['footer__bottom--switchers-box'])
                            }, [
                                _unref(isLanguageSwitcherAvailable) ? (_openBlock(), _createBlock(_unref(LanguageSwitcherButton), {
                                    key: 0,
                                    name: _unref(currentLanguageFlag),
                                    class: _normalizeClass(_ctx.$style['footer__language-switcher']),
                                    onOpenLanguage: _unref(openLanguage)
                                }, null, 8, [
                                    "name",
                                    "class",
                                    "onOpenLanguage"
                                ])) : _createCommentVNode("", true),
                                _unref(isThemeFooterSwitcherEnabled) ? (_openBlock(), _createBlock(ThemeSwitcherButton, {
                                    key: 1
                                })) : _createCommentVNode("", true),
                                _unref(isOddsFormatSwitcherEnabled) ? (_openBlock(), _createBlock(OddsSwitcherButton, {
                                    key: 2
                                })) : _createCommentVNode("", true)
                            ], 2),
                            _createCommentVNode("", true),
                            _createElementVNode("section", {
                                class: _normalizeClass(_ctx.$style['footer__copyright'])
                            }, [
                                _createVNode(FooterCopyright)
                            ], 2)
                        ], 2),
                        _createVNode(_unref(FooterLicense), {
                            "is-grayscale": false,
                            class: _normalizeClass([
                                _ctx.$style['footer__license'],
                                _ctx.$style['footer__license--lower']
                            ])
                        }, null, 8, [
                            "is-grayscale",
                            "class"
                        ])
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'FooterRouteComponent'
                ]
            ]);
        };
    }
});
