import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import DefaultTopBarRouteComponent from '../DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SimpleBrandTopBarRouteComponent',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, null, {
                title: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['flow-top-bar__brand'])
                        }, [
                            _createVNode(VBrand, {
                                alt: _ctx.$t('WEB2_BRAND_NAME_SHORT')
                            }, null, 8, [
                                "alt"
                            ])
                        ], 2)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'SimpleBrandTopBarRouteComponent'
                ]
            ]);
        };
    }
});
