import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = [
    "width",
    "height",
    "src",
    "alt"
];
const _hoisted_3 = [
    "width",
    "height",
    "src",
    "alt"
];
import { useFooterFollowUs } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterFollowUs',
    props: {
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    setup (__props) {
        const { logos, getLogo } = useFooterFollowUs();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['footer__follow'])
            }, [
                _ctx.showTitle ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['footer__follow-title'])
                }, _toDisplayString(_ctx.$t('WEB2_FOOTER_FOLLOW_US_TITLE')), 3)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['footer__follow-list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(logos), (logo, index)=>(_openBlock(), _createElementBlock(_Fragment, {
                            key: index
                        }, [
                            logo.href ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: logo.href,
                                target: "_blank",
                                class: _normalizeClass(_ctx.$style['footer__follow-item'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass(_ctx.$style['footer__follow-item']),
                                    width: logo.width || 32,
                                    height: logo.height || 32,
                                    src: _unref(getLogo)(logo),
                                    alt: logo.alt,
                                    loading: "lazy"
                                }, null, 10, _hoisted_2)
                            ], 10, _hoisted_1)) : (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['footer__follow-item']),
                                width: logo.width || 32,
                                height: logo.height || 32,
                                src: _unref(getLogo)(logo),
                                alt: logo.alt,
                                loading: "lazy"
                            }, null, 10, _hoisted_3))
                        ], 64))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FooterFollowUs'
                ]
            ]);
        };
    }
});
