import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import { useCustomerNotificationsUnreadCounter } from 'web/src/modules/customer-notifications/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderNotificationsButton',
    setup (__props) {
        const { unreadCounter } = useCustomerNotificationsUnreadCounter();
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                class: _normalizeClass(_ctx.$style['header-notification-button']),
                to: {
                    name: _unref(RouteName).CUSTOMER_NOTIFICATIONS
                }
            }, {
                default: _withCtx(()=>[
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createVNode(LButton, {
                                "icon-name": _unref(IconName).BELL,
                                "icon-size": _unref(IconSize).SIZE_20,
                                rounded: "",
                                kind: _unref(ButtonKind).HEADER_DARK_VERSION,
                                height: _unref(ButtonHeight).SMALL
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind",
                                "height"
                            ]),
                            _unref(unreadCounter) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['header-notification-button__indicator'])
                            }, null, 2)) : _createCommentVNode("", true)
                        ], 64))
                    ]),
                _: 1
            }, 8, [
                "class",
                "to"
            ])), [
                [
                    _directive_auto_id,
                    'HeaderNotificationsButton'
                ]
            ]);
        };
    }
});
