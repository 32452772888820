import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { useHeaderLogo } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderLogo',
    setup (__props) {
        const { tag, componentProperties, onClick } = useHeaderLogo();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tag)), _mergeProps(_unref(componentProperties), {
                class: {
                    [_ctx.$style['header__logo']]: true
                },
                onClick: _unref(onClick)
            }), {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 16, [
                "class",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'HeaderLogo'
                ]
            ]);
        };
    }
});
