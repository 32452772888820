import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { useHeaderRegistration } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderRegistration',
    setup (__props) {
        const { onLoginClick, onRegisterClick } = useHeaderRegistration();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['header-registration'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(LButton, {
                        label: _ctx.$t('WEB2_DO_LOGIN'),
                        tag: _unref(Tag).ROUTER_LINK,
                        href: _ctx.$router.resolve({
                            name: _unref(RouteName).LOGIN
                        }).fullPath,
                        height: _unref(ButtonHeight).SMALL,
                        kind: _unref(ButtonKind).TRANSPARENT_DARK_VERSION,
                        "is-uppercase": false,
                        onClick: _unref(onLoginClick)
                    }, null, 8, [
                        "label",
                        "tag",
                        "href",
                        "height",
                        "kind",
                        "onClick"
                    ]),
                    _createVNode(LButton, {
                        label: _ctx.$t('JSPNAV_USTAT_REGISTER'),
                        tag: _unref(Tag).ROUTER_LINK,
                        href: _ctx.$router.resolve({
                            name: _unref(RouteName).REGISTRATION
                        }).fullPath,
                        height: _unref(ButtonHeight).SMALL,
                        kind: _unref(ButtonKind).PRIMARY_DARK_VERSION,
                        "is-uppercase": false,
                        onClick: _unref(onRegisterClick)
                    }, null, 8, [
                        "label",
                        "tag",
                        "href",
                        "height",
                        "kind",
                        "onClick"
                    ])
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeaderRegistration'
                ]
            ]);
        };
    }
});
