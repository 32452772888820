import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = [
    "src",
    "alt",
    "width",
    "height"
];
const _hoisted_3 = [
    "src",
    "alt",
    "width",
    "height"
];
const _hoisted_4 = [
    "src"
];
const _hoisted_5 = [
    "href"
];
const _hoisted_6 = [
    "src"
];
const _hoisted_7 = [
    "src"
];
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useFooterLicense } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterLicense',
    props: {
        isLanding: {
            type: Boolean
        },
        isPanel: {
            type: Boolean
        },
        isGrayscale: {
            type: Boolean
        },
        curacaoSize: {}
    },
    setup (__props) {
        const props = __props;
        const { doShowLogos, doShowGenericLogos, isKwkLogoEnabled, isCrcLogoEnabled, isAnjouanLBZLogoEnabled, isAnjouanTBZLogoEnabled, kwkUrl, crcUrl, logos, getLogo, dynamicSize } = useFooterLicense(props);
        const { isLoggedIn } = useIsLoggedIn();
        const kwkImage = // eslint-disable-next-line import/no-dynamic-require
        require("web/src/assets/logotypes/li/default/license/kahnawake.svg");
        const crcDisabledImage = // eslint-disable-next-line import/no-dynamic-require
        require("web/src/assets/logotypes/li/default/license/curacao-disabled.png");
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(doShowLogos) ? _withDirectives((_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['footer-license']]: true,
                    [_ctx.$style['footer-license--landing']]: _ctx.isLanding,
                    [_ctx.$style['footer-license--panel']]: _ctx.isPanel,
                    [_ctx.$style['footer-license--grayscale']]: _ctx.isGrayscale
                })
            }, [
                _unref(doShowGenericLogos) ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 0
                }, _renderList(_unref(logos), (logo, index)=>(_openBlock(), _createElementBlock(_Fragment, {
                        key: index
                    }, [
                        logo.href ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: logo.href,
                            class: _normalizeClass({
                                [_ctx.$style['footer-license__item']]: true,
                                [_ctx.$style['footer-license__item--colored']]: logo.isDefaultColor
                            })
                        }, [
                            _createElementVNode("img", {
                                class: _normalizeClass({
                                    [_ctx.$style['footer-license__item']]: true,
                                    [_ctx.$style['footer-license__item--colored']]: logo.isDefaultColor
                                }),
                                src: _unref(getLogo)(logo),
                                alt: logo.alt,
                                loading: "lazy",
                                width: _unref(dynamicSize)(logo.width),
                                height: _unref(dynamicSize)(logo.height)
                            }, null, 10, _hoisted_2)
                        ], 10, _hoisted_1)) : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            class: _normalizeClass({
                                [_ctx.$style['footer-license__item']]: true,
                                [_ctx.$style['footer-license__item--colored']]: logo.isDefaultColor
                            }),
                            src: _unref(getLogo)(logo),
                            alt: logo.alt,
                            loading: "lazy",
                            width: _unref(dynamicSize)(logo.width),
                            height: _unref(dynamicSize)(logo.height)
                        }, null, 10, _hoisted_3))
                    ], 64))), 128)) : _createCommentVNode("", true),
                _unref(isCrcLogoEnabled) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(crcUrl) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: 0,
                        id: "apg-seal-container",
                        class: _normalizeClass({
                            [_ctx.$style['footer-license__item']]: true,
                            [_ctx.$style['footer-license__item--link']]: true,
                            [_ctx.$style['footer-license__item--crc']]: true
                        }),
                        "data-apg-seal-id": "0dd241d4-88f7-4a0b-8325-0c40ac10fc44",
                        "data-apg-image-size": "60",
                        "data-apg-image-type": "basic-small"
                    }, null, 2)), [
                        [
                            _vShow,
                            !_unref(isLoggedIn)
                        ]
                    ]) : _withDirectives((_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['footer-license__item']),
                        src: _unref(crcDisabledImage),
                        alt: "Curacao",
                        loading: "lazy",
                        width: "32",
                        height: "32"
                    }, null, 10, _hoisted_4)), [
                        [
                            _vShow,
                            !_unref(isLoggedIn)
                        ]
                    ])
                ], 64)) : _createCommentVNode("", true),
                _unref(isKwkLogoEnabled) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _unref(kwkUrl) ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: _normalizeClass({
                            [_ctx.$style['footer-license__item']]: true,
                            [_ctx.$style['footer-license__item--link']]: true
                        }),
                        href: _unref(kwkUrl),
                        target: "_blank"
                    }, [
                        _createElementVNode("img", {
                            src: _unref(kwkImage),
                            alt: "Kahnawake",
                            loading: "lazy",
                            width: "106",
                            height: "26",
                            class: _normalizeClass(_ctx.$style['footer-license__item--filter'])
                        }, null, 10, _hoisted_6)
                    ], 10, _hoisted_5)) : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: _normalizeClass({
                            [_ctx.$style['footer-license__item']]: true,
                            [_ctx.$style['footer-license__item--filter']]: true
                        }),
                        src: _unref(kwkImage),
                        alt: "Kahnawake",
                        loading: "lazy",
                        width: "auto",
                        height: "26"
                    }, null, 10, _hoisted_7))
                ], 64)) : _createCommentVNode("", true),
                _unref(isAnjouanLBZLogoEnabled) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 3,
                    id: "anj-ffa489c6-d6eb-41e7-9e0f-ab25ac2dd6f5",
                    class: _normalizeClass({
                        [_ctx.$style['footer-license__item']]: true,
                        [_ctx.$style['footer-license__item--link']]: true,
                        [_ctx.$style['footer-license__item--crc']]: true
                    }),
                    "data-anj-seal-id": "ffa489c6-d6eb-41e7-9e0f-ab25ac2dd6f5",
                    "data-anj-image-size": "128",
                    "data-anj-image-type": "basic-small"
                }, null, 2)), [
                    [
                        _vShow,
                        !_unref(isLoggedIn)
                    ]
                ]) : _createCommentVNode("", true),
                _unref(isAnjouanTBZLogoEnabled) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 4,
                    id: "anj-7ad78d18-350b-4099-bf1e-6ba20df24adf",
                    class: _normalizeClass({
                        [_ctx.$style['footer-license__item']]: true,
                        [_ctx.$style['footer-license__item--link']]: true,
                        [_ctx.$style['footer-license__item--crc']]: true
                    }),
                    "data-anj-seal-id": "7ad78d18-350b-4099-bf1e-6ba20df24adf",
                    "data-anj-image-size": "128",
                    "data-anj-image-type": "basic-small"
                }, null, 2)), [
                    [
                        _vShow,
                        !_unref(isLoggedIn)
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FooterLicense'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
