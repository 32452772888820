import { computed, toRef } from 'vue';
import { useNavigationBackButtonStore } from 'web/src/modules/core/store/useNavigationBackButtonStore';
export function useHeaderBarBackButton() {
    const navigationBackButtonStore = useNavigationBackButtonStore();
    toRef(navigationBackButtonStore, 'isBackButtonAvailable');
    const doShowBackButton = computed(()=>false);
    return {
        doShowBackButton
    };
}
