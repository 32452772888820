import { computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { HomePageType } from '@leon-hub/api-sdk';
import { useCustomerBetTypeStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export function useCustomHomepageNavigationItems(items) {
    const betTypeStore = useCustomerBetTypeStore();
    const betType = toRef(()=>betTypeStore.betType);
    const scgStore = useSiteConfigStore();
    const homePageType = toRef(()=>scgStore.homePageType);
    const filteredNavigationItems = computed(()=>{
        if (homePageType.value !== HomePageType.CUSTOM || !betType.value) return items.value;
        return items.value.filter((item)=>item.routeName !== RouteName.HOME);
    });
    return {
        filteredNavigationItems
    };
}
