import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterLogos',
    setup (__props) {
        // empty
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['footer-logos'])
            }, [
                _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "partnership"),
                _renderSlot(_ctx.$slots, "sponsored")
            ], 2)), [
                [
                    _directive_auto_id,
                    'FooterLogos'
                ]
            ]);
        };
    }
});
