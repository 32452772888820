import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ThemeSwitcherButton',
    setup (__props) {
        const router = useRouter();
        function openThemePage() {
            router.push({
                name: RouteName.THEME_SELECT_MODAL
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass(_ctx.$style['theme-switcher-button']),
                onClick: openThemePage
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['theme-switcher-button__visual'])
                }, [
                    _createVNode(_unref(VIcon), {
                        name: _unref(IconName).NIGHT,
                        size: _unref(IconSize).SIZE_16,
                        ":class": _ctx.$style['theme-switcher-button__icon']
                    }, null, 8, [
                        "name",
                        "size",
                        ":class"
                    ]),
                    _createVNode(_unref(VIcon), {
                        name: _unref(IconName).TRIANGLE_DOWN,
                        size: _unref(IconSize).SIZE_16,
                        class: _normalizeClass(_ctx.$style['theme-switcher-button__arrow'])
                    }, null, 8, [
                        "name",
                        "size",
                        "class"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ThemeSwitcherButton'
                ]
            ]);
        };
    }
});
