import { useRootStore, useRouterStore, useNavigationStore, useSiteConfigStore, useApiSettingsStore, useSyncDateTimeStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
export const prefetchConsumer = {
    root: (state)=>useRootStore().handleData(state),
    api: (state)=>useApiSettingsStore().handleApiSettings(state),
    siteConfig: (state)=>useSiteConfigStore().handleSiteConfigSettings(state),
    routing: (state)=>useRouterStore().handleAppRouteConfigs(state),
    navigation: (state)=>useNavigationStore().handleDefaultNavigationConfig(state),
    i18n: (state)=>useI18nStore().updateKeKeys(state),
    serverTime: (time)=>useSyncDateTimeStore().setServerTime(time)
};
