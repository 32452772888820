import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export default function useHeaderLogo() {
    const route = useRoute();
    const router = useRouter();
    const bus = useEventsBus();
    const isHomePage = computed(()=>route.name === RouteName.HOME);
    const tag = computed(()=>isHomePage.value ? Tag.DIV : VRouterLink);
    const componentProperties = computed(()=>{
        if (!isHomePage.value) return {
            to: router.resolve({
                name: RouteName.HOME
            })
        };
        return {};
    });
    function onClick() {
        if (isHomePage.value) bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
    }
    return {
        isHomePage,
        tag,
        componentProperties,
        onClick
    };
}
