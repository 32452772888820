import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id"
];
import FooterNavigationListItem from '../FooterNavigationListItem/FooterNavigationListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterNavigationList',
    props: {
        navigationItems: {},
        isLoggedIn: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("ul", {
                class: _normalizeClass({
                    [_ctx.$style['footer__menu-list']]: true,
                    [_ctx.$style['footer__menu-list--simplified']]: void 0
                })
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationItems, (item)=>(_openBlock(), _createElementBlock("li", {
                        id: item.id,
                        key: item.id
                    }, [
                        _createVNode(FooterNavigationListItem, {
                            item: item,
                            "is-logged-in": _ctx.isLoggedIn,
                            class: _normalizeClass(_ctx.$style['footer__menu-list-item'])
                        }, null, 8, [
                            "item",
                            "is-logged-in",
                            "class"
                        ])
                    ], 8, _hoisted_1))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'FooterNavigationList'
                ]
            ]);
        };
    }
});
