import { prefetchConsumer } from 'web/src/modules/core/apps/main/prefetch/prefetchConsumer';
export function consumePrefetch(prefetchProducer) {
    async function consume(param) {
        let [key, prefetchPromise] = param;
        const state = await prefetchPromise;
        const handler = prefetchConsumer[key];
        handler(state);
    }
    return Promise.all(Object.entries(prefetchProducer).map(consume));
}
