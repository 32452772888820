import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useHeaderRegistration() {
    const analytics = useAnalytics();
    function onLoginClick() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                enter: 'headerEnter'
            }
        });
    }
    function onRegisterClick() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                registration: 'headerRegistration'
            }
        });
    }
    return {
        onLoginClick,
        onRegisterClick
    };
}
