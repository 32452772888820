import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useTheme } from 'web/src/modules/theme/composables';
export default function useFooterFollowUs() {
    const { isDark } = useTheme();
    const scgStore = useSiteConfigStore();
    const footerLogotypesBlock = toRef(scgStore, 'footerLogotypesBlock');
    const logos = computed(()=>footerLogotypesBlock.value?.socialNetworkLogotypesByLocale ?? []);
    function getLogo(logo) {
        return isDark.value ? logo.iconDark : logo.iconLight;
    }
    return {
        logos,
        getLogo
    };
}
