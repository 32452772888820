import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import HeaderLogo from 'web/src/modules/core/components/HeaderLogo/HeaderLogo.vue';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import HeaderRegistration from 'web/src/modules/core/components/HeaderRegistration/HeaderRegistration.vue';
import HeaderProfile from 'web/src/modules/core/components/HeaderProfile/HeaderProfile.vue';
import { VEmpty } from 'web/src/components/Empty';
import HeaderSearchButton from 'web/src/modules/core/components/HeaderSearchButton/HeaderSearchButton.vue';
import { useHeaderBarRouteComponent, useHeaderBarBackButton } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderBarRouteComponent',
    setup (__props) {
        const HeaderNavigation = // eslint-disable-next-line global-require
        require('web/src/modules/core/views/HeaderNavigation/HeaderNavigation.vue').default;
        const ThemeSwitcher = // eslint-disable-next-line global-require
        require('web/src/modules/core/views/ThemeSwitcher/ThemeSwitcher.vue').default;
        const LanguageSwitcher = // eslint-disable-next-line global-require
        require('web/src/modules/i18n/components/LanguageSelector/components/LanguageSwitcherButton/LanguageSwitcherButton.vue').default;
        const { menuOverflowHidden, menuButtonProperties, toggleSidebar, brandName, isLoggedIn, topNavigationItems, formattedBalance, isPromotionsHighlightLinkEnabled, isLandingFestivalEnabled, handleSportlineNavigation, setMenuOverflowVisible, searchButtonProps, onMouseOverSearch, searchClick, isLanguageSwitcherAvailable, currentLanguageFlag, openLanguage, isThemeSwitcherAvailable, isSidebarToggleVisible, toggleTheme, isSimplifiedLogo, hasCustomTheme } = useHeaderBarRouteComponent();
        const { doShowBackButton } = useHeaderBarBackButton();
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['header-bar'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['header-bar__inner'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['header-bar__left']]: true,
                            [_ctx.$style['header-bar__left--overflow-hidden']]: _unref(menuOverflowHidden)
                        })
                    }, [
                        _unref(isSidebarToggleVisible) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['header-bar__menu-button'])
                        }, [
                            (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 1
                            }, _unref(menuButtonProperties), {
                                onClick: _unref(toggleSidebar)
                            }), null, 16, [
                                "onClick"
                            ]))
                        ], 2)) : _createCommentVNode("", true),
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createVNode(HeaderLogo, null, {
                                default: _withCtx(()=>[
                                        _createVNode(VBrand, {
                                            alt: _unref(brandName),
                                            "is-simplified-logo": _unref(isSimplifiedLogo),
                                            "is-header": ""
                                        }, null, 8, [
                                            "alt",
                                            "is-simplified-logo"
                                        ])
                                    ]),
                                _: 1
                            }),
                            _createVNode(_unref(HeaderNavigation), {
                                class: _normalizeClass(_ctx.$style['header-bar__menu']),
                                "navigation-items": _unref(topNavigationItems),
                                "balance-value": _unref(formattedBalance),
                                "is-logged": _unref(isLoggedIn),
                                "is-promotions-highlighted": _unref(isPromotionsHighlightLinkEnabled),
                                "is-landing-festival-enabled": _unref(isLandingFestivalEnabled),
                                "menu-overflow-hidden": _unref(menuOverflowHidden),
                                onSportlineNavigation: _unref(handleSportlineNavigation),
                                onSetParentOverflowVisible: _unref(setMenuOverflowVisible)
                            }, null, 8, [
                                "class",
                                "navigation-items",
                                "balance-value",
                                "is-logged",
                                "is-promotions-highlighted",
                                "is-landing-festival-enabled",
                                "menu-overflow-hidden",
                                "onSportlineNavigation",
                                "onSetParentOverflowVisible"
                            ])
                        ], 64))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['header-bar__right'])
                    }, [
                        (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['header-bar__info'])
                        }, [
                            _withDirectives(_createVNode(VButton, _mergeProps(_unref(searchButtonProps), {
                                title: _ctx.$t('JSP_PLACEHOLDER_SEARCH'),
                                class: _ctx.$style['header-bar__search'],
                                onMouseover: _unref(onMouseOverSearch),
                                onClick: _unref(searchClick)
                            }), null, 16, [
                                "title",
                                "class",
                                "onMouseover",
                                "onClick"
                            ]), [
                                [
                                    _directive_collect,
                                    {
                                        search: 'headerSearch'
                                    },
                                    "click-counter",
                                    {
                                        throttle: true
                                    }
                                ]
                            ]),
                            _unref(isLanguageSwitcherAvailable) && !_unref(isLoggedIn) ? (_openBlock(), _createBlock(_unref(LanguageSwitcher), {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['header-bar__lang']),
                                "no-background": "",
                                "is-header": "",
                                name: _unref(currentLanguageFlag),
                                onOpenLanguage: _unref(openLanguage)
                            }, null, 8, [
                                "class",
                                "name",
                                "onOpenLanguage"
                            ])) : _createCommentVNode("", true),
                            !_unref(hasCustomTheme) && _unref(isThemeSwitcherAvailable) && !_unref(isLoggedIn) ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['header-bar__theme-switcher'])
                            }, [
                                _createVNode(_unref(ThemeSwitcher), {
                                    onChange: _unref(toggleTheme)
                                }, null, 8, [
                                    "onChange"
                                ])
                            ], 2)) : _createCommentVNode("", true)
                        ], 2)),
                        _unref(isLoggedIn) ? (_openBlock(), _createBlock(HeaderProfile, {
                            key: 1
                        })) : (_openBlock(), _createBlock(HeaderRegistration, {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['header-bar__registration'])
                        }, null, 8, [
                            "class"
                        ]))
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeaderBarRouteComponent'
                ]
            ]);
        };
    }
});
